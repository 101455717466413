<template>
  <div class="teachers">
    <div class="teachers__manage">
      <div class="teachers__add-button">
        <vs-button icon="add" type="flat" @click="popupActive = true"
          >Add teacher</vs-button
        >
      </div>
      <export-time-sheet />
    </div>

    <TeacherList
      :status="status"
      @editTeacher="editTeacher($event)"
      @deleteTeacher="deleteTeacher($event)"
      @updateStatus="updateStatusHandler"
    />
    <vs-popup
      class="form-popup teachers__popup"
      classContent="teachers__popup-content"
      title="New teacher"
      :active.sync="popupActive"
    >
      <TeacherForm
        v-if="popupActive"
        :teacher="selectedTeacher"
        @closePopup="closePopup"
      />
    </vs-popup>
  </div>
</template>

<script>
import TeacherList from '@/components/teachers/teachersList.vue'
import TeacherForm from '@/components/teachers/teacherForm.vue'
import { mapState } from 'vuex'
import ExportTimeSheet from '../components/teachers/exportTimeSheet.vue'

export default {
  title: 'SE-Teachers',
  data() {
    return {
      popupActive: false,
      selectedTeacher: {},
      status: 1,
    }
  },
  computed: {
    ...mapState({
      teachers: state => state.teachers.teachers,
      groups: state => state.groups.groups,
      singleTeacher: state => state.teachers.singleTeacher,
    }),
  },
  components: { TeacherList, TeacherForm, ExportTimeSheet },
  created() {
    this.getTeachers()
  },
  methods: {
    getTeachers: function() {
      let { status } = this
      this.$store
        .dispatch('teachers/getTeachersAction', { status })
        .then(() => {})
    },
    editTeacher: function(id) {
      if (!id) {
        this.$vs.notify({
          title: 'Error',
          text: `Error open teacher`,
          color: 'danger',
          position: 'top-right',
        })
        this.selectedRoom = {}
        return
      }
      this.$store
        .dispatch('teachers/getSingleTeacherAction', { id })
        .then(() => {
          this.selectedTeacher = this.singleTeacher
          this.popupActive = true
        })
    },
    deleteTeacher: function(id) {
      this.$store
        .dispatch('teachers/deleteTeachersAction', id)
        .then(() => {
          this.selectedTeacher = {}
          this.popupActive = false
          this.$vs.notify({
            title: 'Removed',
            text: `Teacher removed`,
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(r => {
          this.selectedTeacher = {}
          this.popupActive = false
          this.$vs.notify({
            title: 'Error',
            text: r.message,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    closePopup: function() {
      this.popupActive = false
      this.selectedTeacher = {}
      this.getTeachers()
    },
    updateStatusHandler: function(status) {
      this.status = status
      this.getTeachers()
    },
  },
}
</script>

<style lang="scss">
.vs-con-table header {
  min-height: 50px;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: #eee;
}
.vs-con-table {
  background-color: #fff;
  table {
    font-size: 1;
  }
}
.teachers {
  &__list {
    clear: both;
  }
  &__add-button {
    flex: 1;
    button {
      margin-bottom: 10px;
    }
  }
  &__manage {
    display: flex;
    flex-flow: row nowrap;
  }
  &__filter {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 1.5rem;
    align-items: center;
    gap: 0.8rem;

    .con-select {
      margin: 0;
    }
  }
}
</style>
